import React from 'react';

import { paymentSuccess } from '../DataFiles/standardText';

import '../MainForm/MainForm.css';

const SuccessPage = () => {
  return (
    <div className='pageContainer'>
      {/* <div className="pageHeader">
        <div className="pageTitle"></div>
      </div> */}
      <div className='prevFormCont successPage'>
        <div className='successContainer'>
          <div className='successLeftColumn'>
            <div className='dotsPatternImg'></div>
            <div className='successContent'>
              <div className='successHeader'>{paymentSuccess.header}</div>
              <div className='successBody'>{paymentSuccess.body}</div>
              <div className='successFooter'>
                <div>{paymentSuccess.footer}</div>
                <div>
                  We respect your data and privacy. Click{' '}
                  <a
                    href='https://customerconsents.s3.amazonaws.com/Il+Makiage+Health+Information+Privacy+Policy+7.10.23+-+vSHARED.pdf'
                    target='_blank'
                    rel='noreferrer'
                    className='link'
                  >
                    here
                  </a>{' '}
                  to learn more.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
