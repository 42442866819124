export const heightRange = [
  `3'0"`,
  `3'1"`,
  `3'2"`,
  `3'3"`,
  `3'4"`,
  `3'5"`,
  `3'6"`,
  `3'7"`,
  `3'8"`,
  `3'9"`,
  `3'10"`,
  `3'11"`,
  `4'0"`,
  `4'1"`,
  `4'2"`,
  `4'3"`,
  `4'4"`,
  `4'5"`,
  `4'6"`,
  `4'7"`,
  `4'8"`,
  `4'9"`,
  `4'10"`,
  `4'11"`,
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`,
  `7'3"`,
  `7'4"`,
  `7'5"`,
  `7'6"`,
  `7'7"`,
  `7'8"`,
  `7'9"`,
  `7'10"`,
  `7'11"`,
  `8'0"`,
  `8'1"`,
  `8'2"`,
  `8'3"`,
  `8'4"`,
  `8'5"`,
  `8'6"`,
  `8'7"`,
  `8'8"`,
  `8'9"`,
  `8'10"`,
  `8'11"`,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dates = () => {
  const datesArray = [];
  for (let i = 1; i < 32; i++) {
    datesArray.push(i);
  }
  return datesArray;
};

export const years = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1930;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const questionnaire = [
  //1
  {
    question: "What's your skin type?",
    subText:
      "This helps us include the right ingredients at the right strength for you.",
    answers: [
      {
        answer: "Very dry",
      },
      {
        answer: "Often dry",
      },
      {
        answer: "Bit of Both",
      },
      {
        answer: "Often oily",
      },
      {
        answer: "Very oily",
      },
    ],
    type: "radio",
  },
  //3
  {
    question: "To which gender identity do you most identify with?",
    subText:
      "We ask this question of all of our patients to ensure that we provide you with safe and effective care. We are inclusive of all identities.",
    answers: [
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
      {
        answer: "Trans male/Trans man",
      },
      {
        answer: "Trans female/Trans woman",
      },
      {
        answer: "Genderqueer/Gender non-conforming",
      },
      {
        answer: "Different identity",
        showConditional: true,
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "What is your gender identity?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //4
  {
    question: "What was your sex assigned at birth?",
    answers: [
      {
        answer: "Female",
        showConditional: true,
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  //4.1
  {
    question:
      "Are you pregnant, breastfeeding, or planning to become pregnant in the near future?",
    subText:
      "This information helps your doctor determine the appropriate treatment for you.",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
      },
    ],
    type: "radio",
    isConditional: true,
  },
  {
    question:
      "Please tell us more about your current pregnancy, current breastfeeding, or plan to become pregnant in the future.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //5
  {
    question: "What areas are affected by your acne?",
    answers: [
      {
        answer: "Face",
        showConditional: false,
      },
      {
        answer: "Back",
        showConditional: false,
      },
      {
        answer: "Chest",
        showConditional: false,
      },
      {
        answer: "Other",
        showConditional: true,
      },
    ],
    type: "checkBox",
  },
  {
    question: "Please tell us more about other areas affected by acne.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //6
  {
    question: "Have you ever used these oral medications?",
    subText: "Select all that apply.",
    answers: [
      {
        answer: "Doxycycline",
        showConditional: true,
      },
      {
        answer: "Minocycline",
        showConditional: true,
      },
      {
        answer: "Tetracycline",
        showConditional: true,
      },
      {
        answer: "Erythromycin",
        showConditional: true,
      },
      {
        answer: "Cephalexin/Keflex",
        showConditional: true,
      },
      {
        answer: "Trimethoprim-Sulfamethoxazole/Bactrim",
        showConditional: true,
      },
      {
        answer: "Isotretinoin/Accutane",
        showConditional: true,
      },
      {
        answer: "Birth control pills",
        showConditional: true,
      },
      {
        answer: "Spironolactone",
        showConditional: true,
      },
      {
        answer: "Other oral medications to treat acne",
        showConditional: true,
      },
      {
        answer: "No, never used any of these",
        showConditional: false,
      },
    ],
    type: "checkBox",
  },
  {
    question:
      "Please tell your provider when you last used it, how it worked for you, how your skin reacted, and why you stopped using it.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  //7
  {
    question: "Have you ever used these topical medications?",
    subText: "Select all that apply.",
    answers: [
      {
        answer: "Retinoid/Retinoid-like (e.g. Tretinoin, Retin-A, Retin-A Micro, Tazorac, Tazarotene, Avage, Differin, Adapalene, Epiduo)",
        showConditional: true,
      },
      {
        answer: "Topical antibiotics (e.g. Clindamycin, Erythromycin)",
        showConditional: true,
      },
      {
        answer: "Topical Clindamycin/Tretinoin (e.g. Ziana, Veltin)",
        showConditional: true,
      },
      {
        answer: "Topical antibiotic/benzoyl peroxide (e.g. Duac, Benzaclin, Acanya)",
        showConditional: true,
      },
      {
        answer: "Dapsone/Aczone",
        showConditional: true,
      },
      {
        answer: "Over-the-counter products: ProActiv, Neutrogena Clean and Clear",
        showConditional: true,
      },
      {
        answer: "Other",
        showConditional: true,
      },
      {
        answer: "No, never used any of thesee",
        showConditional: false,
      },
    ],
    type: "checkBox",
  },
  {
    question:
      "Please tell your provider when you last used it, how it worked for you, how your skin reacted, and why you stopped using it.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  //8
  {
    question:
      "Have you had a skin reaction to any of the topical products you have used in the past?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
      },
    ],
    type: "radio",
  },
  {
    question:
      "What was the medication name, dose strength, and frequency of use of the topical product that caused you skin irritation?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showConditional: true,
  },
  //9.1
  {
    question: "Did your skin reaction to the topical product include any of the following?",
    subText: "Select all that apply.",
    answers: [
      {
        answer: "Swelling",
        showConditional: false,
      },
      {
        answer: "Severe redness",
        showConditional: false,
      },
      {
        answer: "Severe peeling",
        showConditional: false,
      },
      {
        answer: "Other",
        showConditional: true,
      },
    ],
    type: "checkBox",
    isConditional: true,
  },
  {
    question:
      "What was your other skin reaction to the topical product?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //10
  {
    question: "Have you ever been diagnosed with any of the following?",
    subText: "Select all that apply.",
    answers: [
      {
        answer: "Rosacea",
      },
      {
        answer: "Perioral dermatitis",
      },
      {
        answer: "Facial eczema",
      },
      {
        answer: "Bacterial of Fungal Folliculutis",
      },
      {
        answer: "No, never had any of these",
      },
    ],
    type: "checkBox",
  },
  //11
  {
    question:
      "Do you have any allergies or medication reactions?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
      },
    ],
    type: "radio",
  },
  {
    question:
      "Please list what you are allergic to and the reaction that each one causes.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //12
  {
    question:
      "Do you currently take any medicines, herbal medicines, or supplements description?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
      },
    ],
    type: "radio",
  },
  {
    question:
      "Please list any current medicines, vitamins, or dietary supplements you take regularly, including the dosage.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //13
  {
    question:
      "Do you have any medical conditions?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
      },
    ],
    type: "radio",
  },
  {
    question:
      "Please tell us more about your medical conditions.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  //14
  {
    question:
      "Is there anything else the doctor should know?",
    answers: [
      {
        answer: "Yes - I'd like to add something else",
        showConditional: true,
      },
      {
        answer: "No - I'm all set",
      },
    ],
    type: "radio",
  },
  {
    question:
      "Please leave your message to your doctor here.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
];

